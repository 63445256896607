<template>
  <b-form @submit.prevent="submit" autocomplete="off" class="formulario_indpredictivos pl-3 pr-3">
    <input style="display: none" type="text" name="fakeusername" />
    <input style="display: none" type="password" name="fakepassword" />

    <b-row>
      <b-col lg="6">
        <b-form-group label="Nombre completo">
          <b-form-input required type="text" v-model="usuario.nombre" placeholder="Nombre completo"></b-form-input>
        </b-form-group>
        <b-form-group label="Email">
          <b-form-input required type="email" v-model="usuario.email" placeholder="Email del usuario"></b-form-input>
        </b-form-group>
        <b-form-group label="Estado">
          <b-form-checkbox v-model="usuario.activo" switch value="1" unchecked-value="0">
            <b v-if="usuario.activo == true">Activo</b>
            <b v-else>Inactivo</b>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label="Usuario">
          <b-form-input required type="text" v-model="usuario.usuario" placeholder="Usuario"></b-form-input>
        </b-form-group>
        <b-form-group label="Contraseña">
          <b-form-input
            v-model="usuario.claveNueva"
            type="password"
            autocomplete="new-password"
            placeholder="Contraseña"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <botonera-edicion url-cancelar="/usuarios"> </botonera-edicion>
  </b-form>
</template>
<script>
import Vue from "vue";
export default {
  props: {
    usuario: Object,
    imagenes: Object,
  },
  data() {
    return {
      optionsRol: [{ text: "Admin", value: true }],
    };
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
  watch: {},
};
</script>
