<template>
  <page fluid="lg" title="Crear usuario">
    <formulario :usuario="usuario" @submit="insertar"></formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiUsuarios from "@/services/usuarios.js";

export default {
  components: { Formulario },
  data() {
    return {
      usuario: apiUsuarios.crear(),
    };
  },
  methods: {
    async insertar() {
      let ok = await apiUsuarios.insertar(this.usuario);
      if (ok) {
        this.$emit("userMessage", "Se ha creado el usuario");
        this.$router.push({ path: "/usuarios" });
      }
    },
  },
};
</script>
